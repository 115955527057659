import { isBrowser } from "@wisr/common";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateContentCards } from "../content-card/content-card.actions";
import { ContentCardComponent } from "../content-card/content-card.component";
import { useGetContentCards } from "../content-card/content-card.hooks";
import { FinancialMoodModal } from "../financial-mood/financial-mood-modal";
import { FinancialMoodWidget } from "../financial-mood/financial-mood-widget";
import { useGetFinancialMood } from "../financial-mood/financial-mood.hooks";
import { Masonry } from "../layout/masonry/masonry";
import { useGetIsAppUser } from "../redux/client-id/client-id.hooks";
import {
  analyticsCustomEvent,
  brazeLoaded,
} from "../shared/analytics/analytics.utils";
import { FetchStatus } from "../shared/redux.consts";
import { ContentCardUpdate } from "../types/content-card";
import { InAppMessage } from "../types/in-app-message";
import { userGetAction } from "../user/user.actions";
import { useGetUser } from "../user/user.hooks";
import { OverviewBorrowingWidget } from "../widgets/borrowing/overview/overview-borrowing-widget.component";
import { PlaceholderCreditWidgetComponent } from "../widgets/credit/placeholder/placeholder-credit-widget.component";
import { ScoreCreditWidgetComponent } from "../widgets/credit/score/score-credit-widget";
import { CTAWidgetComponent } from "../widgets/shared/cta/cta-widget.component";
import localStyle from "./index.scss";
import { HomepageRateEstimateWidget } from "../widgets/borrowing/rate-estimate/homepage-rate-estimate/homepage-rate-estimate-widget";
import { fetchRateOffer } from "../redux/rate-offer/rate-offer.thunk";
import { useGetRateOfferState } from "../redux/rate-offer/rate-offer.hooks";
import { RateOfferWidget } from "../widgets/borrowing/rate-offer/rate-offer-widget";
import { PartnerReferralWidget } from "../widgets/borrowing/referrals/partner-referral-widget";
import { AppProductOfferWidget } from "../widgets/borrowing/app-product-offer/app-product-offer";
import {
  useGetDrivaEligible,
  useGetEstimateEligible,
} from "../widgets/credit/score/score-credit-widget.hooks";

const Index = () => {
  const contentCardState = useGetContentCards();
  const user = useGetUser();
  const dispatch = useDispatch();
  const rateOfferState = useGetRateOfferState();
  const financialMood = useGetFinancialMood();
  const braze = brazeLoaded();
  const isFirstUpdate = React.useRef(true);
  const isFirstViewPageUpdate = React.useRef(true);
  const hasAnyLoans = user.loanCount > 0 || user.applicationCount > 0;
  const hasApplications = user.applicationCount > 0;
  const hasRateOffers = rateOfferState.rateOffers.length > 0;
  const isEstimateEligible = useGetEstimateEligible();
  const isDrivaEligible =
    useGetDrivaEligible() &&
    !isEstimateEligible &&
    !hasAnyLoans &&
    !hasRateOffers;
  const BRAZE_DELAY = 12000; //The time taken by Braze to include a user in a campaign
  const isMoodSet =
    financialMood.financialMoodResponse?.financialMood !== undefined;
  const isAppUser = useGetIsAppUser();

  const saveContentCards = useCallback(() => {
    /* istanbul ignore else */
    if (braze) {
      const cardUpdates: ContentCardUpdate =
        window.appboy.getCachedContentCards();
      dispatch(updateContentCards(cardUpdates.cards));
      window.appboy.subscribeToContentCardsUpdates(
        (updates: ContentCardUpdate) => {
          if (updates) {
            dispatch(updateContentCards(updates.cards));
          }
        }
      );
      window.appboy.requestContentCardsRefresh();
      setTimeout(() => {
        window.appboy.requestContentCardsRefresh();
      }, BRAZE_DELAY);
    }
  }, [braze, dispatch]);

  React.useEffect(() => {
    dispatch(userGetAction());
    if (rateOfferState.status === FetchStatus.IDLE) {
      dispatch(fetchRateOffer());
    }
  }, [dispatch, rateOfferState.status]);

  React.useEffect(() => {
    if (braze) {
      try {
        saveContentCards();
        if (isFirstUpdate.current) {
          isFirstUpdate.current = false;
          window.appboy.subscribeToInAppMessage(
            (inAppMessage: InAppMessage) => {
              window.appboy.display.showInAppMessage(inAppMessage);
            }
          );
        }
      } catch {
        console.error("failed to subscribe to messages");
      }
    }
    /* istanbul ignore next - not covered in cleanup */
    return () => {
      if (braze) {
        window.appboy.removeAllSubscriptions();
      }
    };
  }, [braze, saveContentCards]);

  React.useEffect(() => {
    if (user.info && isFirstViewPageUpdate.current) {
      isFirstViewPageUpdate.current = false;
      analyticsCustomEvent("viewedDashboardHome", {
        "Page name": "Dashboard home",
      });
    }
  }, [user.info]);

  if (!isBrowser()) {
    // Prevents FOUC on initial load
    return null;
  }

  return (
    <>
      <style jsx>{localStyle}</style>

      {user.hasCreditAccount && <FinancialMoodModal />}

      <Masonry>
        <div className="welcome">
          <CTAWidgetComponent type="primary">
            <h2>Hi {user.info?.firstName}</h2>
            <p className="description">
              Designed with your smart part in mind, this dashboard is where
              you&apos;ll access our suite of financial tools, tech and
              resources.
            </p>
          </CTAWidgetComponent>
        </div>

        {isDrivaEligible && <PartnerReferralWidget />}

        {hasAnyLoans && <OverviewBorrowingWidget variation="home" />}

        {isEstimateEligible && !hasRateOffers && !hasApplications && (
          <HomepageRateEstimateWidget />
        )}

        {user.hasCreditAccount ? (
          <ScoreCreditWidgetComponent variation="compact" />
        ) : (
          <PlaceholderCreditWidgetComponent />
        )}

        {hasRateOffers &&
          rateOfferState.rateOffers.map((rateOffer, index) => {
            return <RateOfferWidget key={index} rateOffer={rateOffer} />;
          })}

        {!isAppUser && <AppProductOfferWidget />}

        {isMoodSet && <FinancialMoodWidget financialMood={financialMood} />}

        {contentCardState.contentCards.length > 0 &&
          contentCardState.contentCards.map((card, index) => {
            return (
              <ContentCardComponent key={index} singleContentCard={card} />
            );
          })}
      </Masonry>
    </>
  );
};

export default Index;
