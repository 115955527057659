import { Button, Modal } from "@wisr/react-ui";
import React, { useState } from "react";
import image from "../../../../assets/images/medal.svg";
import { analyticsCustomEvent } from "../../../../shared/analytics/analytics.utils";
import { RateEstimateBorrowingWidgetComponent } from "../rate-estimate-borrowing-widget.component";
import style from "../rate-estimate-borrowing-widget.scss";
import {
  cta,
  disclaimer,
  title,
} from "./zero-fee-rate-estimate-borrowing-widget.constants";

export interface ZeroFeeRateEstimateBorrowingWidgetComponent {
  zeroFeeWidgetExpiry: string;
}

export const ZeroFeeRateEstimateBorrowingWidget: React.FC<
  ZeroFeeRateEstimateBorrowingWidgetComponent
> = ({ zeroFeeWidgetExpiry }) => {
  const [openModal, setOpenModal] = useState(false);

  React.useEffect(() => {
    analyticsCustomEvent("presentedDashboardOffer", {
      "Dashboard offer": "Rate estimate",
      Location: "Dashboard home",
    });
  }, []);

  return (
    <>
      <style jsx>{style}</style>
      <RateEstimateBorrowingWidgetComponent
        type="primary"
        title={title}
        description={
          <ul>
            <li>Great rates for excellent credit</li>
            <li>
              $0 establishment fee for personal loans between $5,000 and
              $30,000, for a limited time
            </li>
            <li>
              <button
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Terms &amp; conditions apply
              </button>
            </li>
          </ul>
        }
        image={image}
        cta={cta}
        clickEvent={() => {
          analyticsCustomEvent("clickedWidgetOffer", {
            "Dashboard offer": "Rate estimate",
            Location: "Dashboard home",
          });
        }}
        theme="white"
      />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        titleText="Terms &amp; Conditions"
        actions={[
          <Button key="close-button">
            <button onClick={() => setOpenModal(false)}>Close</button>
          </Button>,
        ]}
      >
        {disclaimer}
        {zeroFeeWidgetExpiry}
      </Modal>
    </>
  );
};
