import React from "react";
import { Button } from "@wisr/react-ui";
import { WidgetComponent } from "../../../widget.component";
import refinanceStyle from "./refinance-offer-widget.scss";
import { analyticsCustomEvent } from "../../../../shared/analytics/analytics.utils";
import { QUOTE_FORM } from "../../../../shared/url.consts";
import { RATE_ESTIMATE_BID } from "../../../../shared/bid.consts";
import { useGetUser } from "../../../../user/user.hooks";
import { getRefinanceImage } from "./refinance-offer-widget.utils";
import { useGetMoneyMoment } from "../../../credit/score/score-credit-widget.hooks";

export const RefiDashboardWidget = () => {
  const user = useGetUser();
  const moneyMoment = useGetMoneyMoment();
  const icon = getRefinanceImage(moneyMoment?.infographic ?? "");

  React.useEffect(() => {
    const eventTimeout = setTimeout(() => {
      analyticsCustomEvent("presentedDashboardOffer", {
        "Dashboard offer": "Rate estimate",
        Location: "Dashboard home",
        Personalisation: moneyMoment?.offerCode,
      });
    }, 500);

    return () => {
      clearTimeout(eventTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moneyMoment]);

  return (
    <WidgetComponent
      widgetName="refinance refinance-dashboard"
      widgetType="credit"
    >
      <style jsx>{refinanceStyle}</style>
      <div
        className="refinance-widget-wrap"
        data-background={moneyMoment?.color}
      >
        <div className="image">{icon}</div>
        <h2>{moneyMoment?.title}</h2>
        <p className="description">{moneyMoment?.description}</p>
        <Button button="primary" theme="navy">
          <a
            href={`${QUOTE_FORM}?wcToken=${user.access_token}&Product=Dashboard&bid=${RATE_ESTIMATE_BID}`}
            target="_blank"
            rel="noopener nofollow"
            onClick={() => {
              analyticsCustomEvent("clickedWidgetOffer", {
                "Dashboard offer": "Rate estimate",
                Location: "Dashboard home",
                Personalisation: moneyMoment?.offerCode,
              });
            }}
          >
            {moneyMoment?.linkTitle}
          </a>
        </Button>
      </div>
    </WidgetComponent>
  );
};
