import React from "react";
import { WidgetComponent } from "../../../widget.component";
import secondLoanEstimateStyle from "./second-loan-estimate-widget.scss";
import smartPart from "../../../../assets/images/widget-icons/smart-part.svg";
import { Button, Modal, SkeletonLoader } from "@wisr/react-ui";
import { navigate } from "gatsby";
import {
  amplitudeHelper,
  analyticsCustomEvent,
  brazeLoaded,
} from "../../../../shared/analytics/analytics.utils";
import { useDispatch } from "react-redux";
import { fetchBrokerDetails } from "../../../../redux/broker-details/broker-details.thunk";
import { useGetBrokerDetailsState } from "../../../../redux/broker-details/broker-details.hooks";
import { FetchStatus } from "../../../../shared/redux.consts";
import { isBrowserMobile } from "../../../../shared/browser/browser.utils";
import {
  getBrokerContactSection,
  getWidgetCopy,
} from "./second-loan-estimate-widget.utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isBrowser } from "@wisr/common";

interface SecondLoanEstimateWidgetProps {
  widgetLocation?: string;
}

export const SecondLoanEstimateWidget: React.FC<
  SecondLoanEstimateWidgetProps
> = ({ widgetLocation = "Dashboard home" }) => {
  const dispatch = useDispatch();
  const { secondLoanBrokerContactEnabled } = useFlags();
  const braze = brazeLoaded();
  const brokerDetailsState = useGetBrokerDetailsState();
  const [brokerDetailsModal, setBrokerDetailsModal] = React.useState(false);
  const [brokerContacted, setBrokerContacted] = React.useState(
    FetchStatus.IDLE
  );
  const hasActiveBroker =
    secondLoanBrokerContactEnabled &&
    brokerDetailsState.status === FetchStatus.SUCCESS &&
    brokerDetailsState.details !== undefined &&
    brokerDetailsState.details.suspended === false;
  const urlParams = isBrowser() && new URLSearchParams(window.location.search);
  const openParam = urlParams && urlParams.get("open");
  const CONTACT_EVENT_NAME = "Clicked Contact broker";

  const clickedLetsGo = () => {
    analyticsCustomEvent("clickedWidgetOffer", {
      "Dashboard offer": "Second loan estimate",
      Location: widgetLocation,
    });
    if (hasActiveBroker) {
      setBrokerDetailsModal(true);
    } else {
      navigate("/quote");
    }
  };

  const clickedContactBroker = () => {
    if (braze) {
      window.appboy.logCustomEvent(CONTACT_EVENT_NAME);
      amplitudeHelper.sendEvent(CONTACT_EVENT_NAME);
      setBrokerContacted(FetchStatus.SUCCESS);
    } else {
      amplitudeHelper.sendEvent(
        "Error requesting contact from broker for second loan"
      );
      setBrokerContacted(FetchStatus.FAILED);
    }
  };

  React.useEffect(() => {
    analyticsCustomEvent("presentedDashboardOffer", {
      "Dashboard offer": "Second loan estimate",
      Location: widgetLocation,
    });
  }, [widgetLocation]);

  React.useEffect(() => {
    if (brokerDetailsState.status === FetchStatus.IDLE) {
      dispatch(fetchBrokerDetails());
    }
  }, [brokerDetailsState.status, dispatch]);

  React.useEffect(() => {
    if (openParam === "second-loan" && hasActiveBroker) {
      setBrokerDetailsModal(true);
    }
  }, [hasActiveBroker, openParam]);

  if (
    brokerDetailsState.status === FetchStatus.IDLE ||
    brokerDetailsState.status === FetchStatus.LOADING
  ) {
    return (
      <WidgetComponent widgetName="second-loan-estimate" widgetType="other">
        <SkeletonLoader lines={7} />
      </WidgetComponent>
    );
  }

  return (
    <>
      <WidgetComponent widgetName="second-loan-estimate" widgetType="other">
        <style jsx>{secondLoanEstimateStyle}</style>
        <div className="second-loan-estimate-widget">
          <div className="widget-title">
            <img src={smartPart} />
          </div>
          <h2>How much you can borrow on second loan with Wisr?</h2>
          <p>{getWidgetCopy(hasActiveBroker)}</p>
          <Button button="primary" theme="navy" fullWidth>
            <button onClick={clickedLetsGo}>Let&rsquo;s go</button>
          </Button>
        </div>
      </WidgetComponent>
      {hasActiveBroker && brokerDetailsState.details && (
        <Modal
          open={brokerDetailsModal}
          onClose={
            /* istanbul ignore next */ () => setBrokerDetailsModal(false)
          }
        >
          <div className="contact-broker-modal">
            <style jsx>{secondLoanEstimateStyle}</style>
            <h3>Contact your broker to see your personalised rate</h3>
            <p>Your Wisr loan was settled by:</p>
            <div className="broker-details">
              <div className="detail-row">
                <span>Broker</span>
                <span>{brokerDetailsState.details.name}</span>
              </div>
              <hr />
              <div className="detail-row">
                <span>Email</span>
                <span>
                  {isBrowserMobile()
                    ? brokerDetailsState.details.email.slice(0, 20) + "..."
                    : brokerDetailsState.details.email}
                </span>
              </div>
            </div>
            {getBrokerContactSection(brokerContacted, clickedContactBroker)}
          </div>
        </Modal>
      )}
    </>
  );
};
