import { Button } from "@wisr/react-ui";
import { Link } from "gatsby";
import React from "react";
import creditScore from "../../../assets/images/credit-score-orange.svg";
import { CTAWidgetComponent } from "../../shared/cta/cta-widget.component";
import placeholderStyle from "./placeholder-credit-widget.scss";
import { useDispatch } from "react-redux";
import { scoreCreditGetAction } from "../score/score-credit-widget.actions";

export const PlaceholderCreditWidgetComponent = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(scoreCreditGetAction());
  }, [dispatch]);

  return (
    <CTAWidgetComponent type="credit">
      <style jsx>{placeholderStyle}</style>
      <img src={creditScore} />
      <div className="widget-title">
        <h2>Check your credit scores</h2>
      </div>
      <div className="widget-data">
        <div className="description">
          <p>
            Knowing your scores will help you better understand your position
            and see where you stand with lenders.
          </p>
          <p>
            Checking is free, easy and won&apos;t affect your credit scores.
          </p>
        </div>
        <Button button="primary" theme="white">
          <Link to="/credit/create-profile/">See my scores</Link>
        </Button>
      </div>
    </CTAWidgetComponent>
  );
};
