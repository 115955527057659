import { useLoadingStatus } from "@wisr/common";
import { CheckboxOption, SkeletonLoader } from "@wisr/react-ui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { analyticsClickEvent } from "../shared/analytics/analytics.utils";
import { FinancialMoodState } from "../types/financial-mood";
import { WidgetComponent } from "../widgets/widget.component";
import {
  financialMoodToggleModalAction,
  GET_FINANCIAL_MOOD_LOADING,
} from "./financial-mood.actions";
import financialMoodStyle from "./financial-mood.scss";
import { findMoments, findMood } from "./financial-mood.utils";

interface FinancialMood {
  financialMood: FinancialMoodState;
}

export const FinancialMoodWidget: React.FC<FinancialMood> = ({
  financialMood,
}) => {
  const [financialMoodLoading] = useLoadingStatus(GET_FINANCIAL_MOOD_LOADING);
  const [selectedMood, setSelectedMood] = useState<JSX.Element>();
  const [selectedMoments, setSelectedMoments] = useState<CheckboxOption[]>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setSelectedMood(findMood(financialMood).label);
    setSelectedMoments(findMoments(financialMood));
  }, [financialMood]);

  const preferencesSubtitles = [
    "Your financial confidence is:",
    "In the next 12 months you hope to:",
  ];

  return (
    <WidgetComponent widgetName="financial-mood" widgetType="other">
      <style jsx>{financialMoodStyle}</style>
      {financialMoodLoading ? (
        <div className="loading-wrapper">
          <SkeletonLoader lines={8} />
        </div>
      ) : (
        <div className="widget-container">
          <div className="widget-title">
            <h2>Your preferences</h2>
            <div className="edit">
              <button
                className="edit-button"
                onClick={() => {
                  dispatch(financialMoodToggleModalAction());
                  analyticsClickEvent("Edit financial moods");
                }}
              />
            </div>
          </div>
          <div className="widget-contents">
            <p className="preference-subtitle">{preferencesSubtitles[0]}</p>
            <label className="preference-field">
              <span className="money-moment">{selectedMood}</span>
            </label>
            <p className="preference-subtitle">{preferencesSubtitles[1]}</p>
            {selectedMoments &&
              selectedMoments.map((moment) => (
                <label className="preference-field" key={moment.value}>
                  {moment.label}
                </label>
              ))}
          </div>
        </div>
      )}
    </WidgetComponent>
  );
};
