import { Button } from "@wisr/react-ui";
import React from "react";
import { analyticsClickEvent } from "../../../shared/analytics/analytics.utils";
import { RATE_ESTIMATE_BID } from "../../../shared/bid.consts";
import { QUOTE_FORM } from "../../../shared/url.consts";
import { AnalyticsClickEvents } from "../../../types/analytics";
import { useGetUser } from "../../../user/user.hooks";
import { CTAWidgetComponent } from "../../shared/cta/cta-widget.component";
import localStyle from "./rate-estimate-borrowing-widget.scss";

interface RateEstimateProps {
  description: React.ReactNode;
  title: React.ReactNode | string;
  image: string;
  cta: AnalyticsClickEvents;
  clickEvent?: () => void;
  type?: "credit" | "loan" | "primary";
  theme?: "navy" | "teal" | "white";
}

export const RateEstimateBorrowingWidgetComponent: React.FC<
  RateEstimateProps
> = ({
  description,
  title,
  image,
  cta,
  clickEvent,
  type = "loan",
  theme = "teal",
}) => {
  const user = useGetUser();

  return (
    <div className="rate-estimate">
      <style jsx>{localStyle}</style>
      <CTAWidgetComponent type={type}>
        <div className="image">
          <img src={image} />
        </div>
        <div className="widget-title">
          <h2>{title}</h2>
        </div>
        <div className="widget-data">
          <div className="widget-description">{description}</div>
          <div className="widget-cta">
            <div className="cta">
              <Button button="primary" theme={theme}>
                <a
                  href={`${QUOTE_FORM}?wcToken=${
                    user?.access_token || ""
                  }&Product=Dashboard&bid=${RATE_ESTIMATE_BID}`}
                  target="_blank"
                  rel="noopener nofollow"
                  onClick={() => {
                    if (clickEvent) {
                      clickEvent();
                    } else {
                      analyticsClickEvent(cta);
                    }
                  }}
                >
                  {cta}
                </a>
              </Button>
            </div>
          </div>
        </div>
      </CTAWidgetComponent>
    </div>
  );
};
