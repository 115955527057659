import { isDefined } from "@wisr/common";
import React from "react";
import {
  isBrowserMobile,
  isBrowserTablet,
} from "../../shared/browser/browser.utils";
import style from "./masonry.scss";

type ReactEl = React.ReactChild | React.ReactFragment | React.ReactPortal;

interface MasonryProps {
  desktopColumns?: number;
  tabletColumns?: number;
}

export const Masonry: React.FC<MasonryProps> = ({
  children,
  desktopColumns = 3,
  tabletColumns = 2,
}) => {
  const deskColumns: ReactEl[][] = [];
  const tabColumns: ReactEl[][] = [];

  React.Children.toArray(children).forEach((child, index) => {
    const deskPos = index % desktopColumns;
    const tabPos = index % tabletColumns;
    if (!isDefined(deskColumns[deskPos])) {
      deskColumns[deskPos] = [];
    }
    if (!isDefined(tabColumns[tabPos])) {
      tabColumns[tabPos] = [];
    }
    deskColumns[deskPos].push(child);
    tabColumns[tabPos].push(child);
  });

  return (
    <div className="masonry">
      <style jsx>{style}</style>
      {isBrowserMobile()
        ? children
        : isBrowserTablet()
        ? tabColumns.map((col, index) => (
            <div
              className="col"
              key={index}
              style={{ maxWidth: `calc(1 / ${tabletColumns} * 100%)` }}
            >
              {col}
            </div>
          ))
        : deskColumns.map((col, index) => (
            <div
              className="col"
              key={index}
              style={{ maxWidth: `calc(1 / ${desktopColumns} * 100%)` }}
            >
              {col}
            </div>
          ))}
    </div>
  );
};
