import React from "react";
import { FilteredContentCard } from "../types/content-card";
import { WidgetComponent } from "../widgets/widget.component";
import { NavLink } from "../widgets/shared/nav-link/nav-link.component";
import contentCardStyle from "./content-card.scss";
import { ContentCardLink } from "./link/content-card-link.component";
import { shortenCopy } from "./content-card.utils";

export interface ContentCardData {
  singleContentCard: FilteredContentCard;
}

export const ContentCardComponent: React.FC<ContentCardData> = ({
  singleContentCard,
}) => {
  const IMPRESSION_DELAY = 2000; //Component will be rendered more than once on initial load, delay is to avoid duplicate impressions
  const TITLE_LENGTH = 22;
  const DESCRIPTION_LENGTH = 72;
  const cardTitle = shortenCopy(singleContentCard.title, TITLE_LENGTH);
  const cardDescription = shortenCopy(
    singleContentCard.description,
    DESCRIPTION_LENGTH
  );

  React.useEffect(() => {
    const impressionTimeout = setTimeout(() => {
      window.appboy.logCardImpressions([singleContentCard], true);
    }, IMPRESSION_DELAY);
    return () => {
      clearTimeout(impressionTimeout);
    };
  }, [singleContentCard]);

  return (
    <WidgetComponent widgetName="content-card" widgetType="other">
      <style jsx>{contentCardStyle}</style>
      <div className="content-card">
        {singleContentCard.imageUrl && (
          <ContentCardLink singleContentCard={singleContentCard}>
            <img src={singleContentCard.imageUrl} />
          </ContentCardLink>
        )}
        {singleContentCard.extras.eyebrow && (
          <h3 className="subheading">{singleContentCard.extras.eyebrow}</h3>
        )}
        <ContentCardLink singleContentCard={singleContentCard}>
          <h2>{cardTitle}</h2>
        </ContentCardLink>
        {singleContentCard.description &&
          singleContentCard.description != "blank" && <p>{cardDescription}</p>}
        <NavLink
          className="article-link"
          external
          to={singleContentCard.url}
          onClick={() => {
            window.appboy.logCardClick(singleContentCard, true);
          }}
        >
          {singleContentCard.linkText}
        </NavLink>
      </div>
    </WidgetComponent>
  );
};
