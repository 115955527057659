import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { DefaultRateEstimateBorrowingWidgetComponent } from "../default/default-rate-estimate-borrowing-widget.component";
import { RefiDashboardWidget } from "../refinance-offer/refi-dashboard-widget";
import { SplitRateEstimateWidget } from "../split/split-rate-estimate-widget";
import { ZeroFeeRateEstimateBorrowingWidget } from "../zero-fee/zero-fee-rate-estimate-borrowing-widget";
import { useGetRefiEligible } from "../../../credit/score/score-credit-widget.hooks";
import { useGetUser } from "../../../../user/user.hooks";
import { SecondLoanEstimateWidget } from "../second-loan-estimate/second-loan-estimate-widget";

export const HomepageRateEstimateWidget: React.FC = () => {
  const user = useGetUser();
  const hasAnyLoans = user.loanCount > 0;
  const refiEligible = useGetRefiEligible();
  const { zeroFeeWidgetExpiry, rateEstimateVariant } = useFlags();

  if (hasAnyLoans) {
    return <SecondLoanEstimateWidget />;
  } else if (refiEligible) {
    return <RefiDashboardWidget />;
  } else if (zeroFeeWidgetExpiry) {
    return (
      <ZeroFeeRateEstimateBorrowingWidget
        zeroFeeWidgetExpiry={zeroFeeWidgetExpiry}
      />
    );
  } else if (rateEstimateVariant) {
    return <SplitRateEstimateWidget />;
  } else {
    return <DefaultRateEstimateBorrowingWidgetComponent />;
  }
};
