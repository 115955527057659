import React from "react";
import { WidgetComponent } from "../../widget.component";
import style from "./image-widget.scss";

interface ImageWidgetProps {
  image: string;
  alt?: string;
  background?: "navy" | "info";
  headline?: string;
}

export const DefaultAlt = "Main image";

export const ImageWidget: React.FC<ImageWidgetProps> = ({
  image,
  alt,
  children,
  background,
  headline,
}) => {
  return (
    <WidgetComponent widgetName="" widgetType="other">
      <style jsx>{style}</style>
      <div className={`wrapper ${background}`}>
        {headline && <h3 className="headline">{headline}</h3>}
        <div className={`image ${!headline ? "no-headline" : ""}`}>
          <img src={image} alt={alt || DefaultAlt} />
        </div>
        <div className="content">{children}</div>
      </div>
    </WidgetComponent>
  );
};
