import { Button, Modal } from "@wisr/react-ui";
import React, { useState } from "react";
import image from "../../../../assets/images/loans-personalised-yellow.svg";
import { analyticsCustomEvent } from "../../../../shared/analytics/analytics.utils";
import { RateEstimateBorrowingWidgetComponent } from "../rate-estimate-borrowing-widget.component";
import style from "../rate-estimate-borrowing-widget.scss";
import {
  cta,
  disclaimer,
  title,
} from "./default-rate-estimate-borrowing-widget.constants";
import { useGetUser } from "../../../../user/user.hooks";

export const DefaultRateEstimateBorrowingWidgetComponent = () => {
  const user = useGetUser();
  const [openModal, setOpenModal] = useState(false);

  React.useEffect(() => {
    analyticsCustomEvent(
      "presentedDashboardOffer",
      {
        "Dashboard offer": "Rate estimate",
        Location: "Dashboard home",
      },
      {
        "RE Split Tests": "Dash_RE_Experiment_v1",
      }
    );
    analyticsCustomEvent("presentedDashboardOfferWithEmail", {
      "Dashboard offer": "Rate estimate",
      Location: "Dashboard home",
      email: user.profile.email,
    });
  }, [user.profile.email]);

  return (
    <>
      <style jsx>{style}</style>
      <RateEstimateBorrowingWidgetComponent
        title={title}
        description={
          <>
            <p>
              For the stuff that can’t wait, Wisr loans are tailored to you and
              your unique credit score. Smart, right?
            </p>
            <p>
              Seeing your rate estimate is easy and takes less than 2 minutes.*{" "}
              <button
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Terms and conditions apply.
              </button>
            </p>
          </>
        }
        image={image}
        cta={cta}
        clickEvent={() => {
          analyticsCustomEvent("clickedWidgetOffer", {
            "Dashboard offer": "Rate estimate",
            Location: "Dashboard home",
          });
        }}
      />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        titleText="Terms &amp; Conditions"
        selfClose={true}
        actions={[
          <Button key="close-button">
            <button onClick={() => setOpenModal(false)}>Close</button>
          </Button>,
        ]}
      >
        {disclaimer}
      </Modal>
    </>
  );
};
