import { toCurrency } from "@wisr/common";
import { Button } from "@wisr/react-ui";
import React from "react";
import { analyticsCustomEvent } from "../../../shared/analytics/analytics.utils";
import { BadgeComponent } from "../../../shared/badge/badge.component";
import { RateOffer } from "../../../types/rate-offer";
import { WidgetComponent } from "../../widget.component";
import { getWidgetIcon } from "../rate-estimate/personalised/personalised-rate-estimate-widget.utils";
import rateOfferStyle from "./rate-offer-widget.scss";
import {
  getRateOfferCopy,
  getRateOfferPurposeText,
} from "./rate-offer-widget.utils";

export interface RateOfferWidgetProps {
  rateOffer: RateOffer;
}

export const RateOfferWidget: React.FC<RateOfferWidgetProps> = ({
  rateOffer,
}) => {
  const expiryDays = `Expires ${rateOffer.expiryInDays.toString()} days`;
  const purpose = getRateOfferPurposeText(rateOffer.purpose);
  const offerCopy = getRateOfferCopy(rateOffer.purpose);
  const icon = getWidgetIcon(rateOffer.purpose);

  React.useEffect(() => {
    analyticsCustomEvent("loadedRateEstimateCompletedWidget");
  }, []);

  return (
    <WidgetComponent widgetName="rate-offer" widgetType="other">
      <style jsx>{rateOfferStyle}</style>
      <div className="rate-offer-widget">
        <div className="widget-title">
          <div className={`icon ${rateOffer.purpose}`}>{icon}</div>
          <BadgeComponent badge="warning">{expiryDays}</BadgeComponent>
        </div>
        <h3>
          {purpose} <span className="divider">&#8226;</span>{" "}
          {toCurrency(rateOffer.loanAmount, false)}
        </h3>
        <h2>Ready to start your loan application?</h2>
        <p>{offerCopy}</p>
        <Button button="primary" theme="navy" fullWidth>
          <a
            href={rateOffer.applyUrl}
            target="_blank"
            rel="noopener nofollow"
            onClick={() => {
              analyticsCustomEvent("clickedRateEstimateCompletedWidget");
            }}
          >
            See my rates
          </a>
        </Button>
      </div>
    </WidgetComponent>
  );
};
