import React from "react";
import { ContentCard } from "../../types/content-card";
import linkStyle from "./content-card-link.scss";

export interface ContentCardLinkProps {
  singleContentCard: ContentCard;
}

export const ContentCardLink: React.FC<ContentCardLinkProps> = ({
  singleContentCard,
  children,
}) => {
  return (
    <>
      <style jsx>{linkStyle}</style>
      <a
        className="article-link"
        href={singleContentCard.url}
        target="_blank"
        rel="noopener nofollow"
        onClick={() => {
          window.appboy.logCardClick(singleContentCard, true);
        }}
      >
        {children}
      </a>
    </>
  );
};
