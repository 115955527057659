import React from "react";
import { WidgetComponent } from "../../widget.component";
import style from "./app-product-offer.scss";
import qrCode from "../../../assets/images/app-product/wisr-app-qr-code.svg";
import { Button, SkeletonLoader } from "@wisr/react-ui";
import { getAppOfferDetails } from "./app-product-offer.utils";
import { useDispatch } from "react-redux";
import { fetchHomeProducts } from "../../../redux/home-products/home-products.thunk";
import { useGetHomeProductsState } from "../../../redux/home-products/home-products.hooks";
import { isBrowserMobile } from "../../../shared/browser/browser.utils";
import { FetchStatus } from "../../../shared/redux.consts";
import { BRAND_WEBSITE } from "../../../shared/url.consts";
import { analyticsCustomEvent } from "../../../shared/analytics/analytics.utils";

export const AppProductOfferWidget = () => {
  const dispatch = useDispatch();
  const homeProductsState = useGetHomeProductsState();
  const offerDetails = getAppOfferDetails(homeProductsState.homeProducts);
  const eventSent = React.useRef(false);
  const ANALYTICS_DELAY = 1000;

  React.useEffect(() => {
    if (homeProductsState.status === FetchStatus.IDLE) {
      dispatch(fetchHomeProducts());
    }
  }, [dispatch, homeProductsState.status]);

  React.useEffect(() => {
    if (
      homeProductsState.status === FetchStatus.SUCCESS &&
      !eventSent.current
    ) {
      eventSent.current = true;
      setTimeout(() => {
        analyticsCustomEvent("presentedDashboardOffer", {
          "Dashboard offer": `App offer for ${offerDetails.products} user`,
          Location: "Dashboard home",
        });
      }, ANALYTICS_DELAY);
    }
  }, [homeProductsState.status, offerDetails]);

  if (homeProductsState.status !== FetchStatus.SUCCESS) {
    return (
      <WidgetComponent widgetName="app-product-offer" widgetType="other">
        <SkeletonLoader lines={8} />
      </WidgetComponent>
    );
  }

  return (
    <WidgetComponent widgetName="app-product-offer" widgetType="other">
      <style jsx>{style}</style>
      <div className="app-product-offer-widget">
        <div className={`top-section ${offerDetails.colour}`}>
          <h3>{offerDetails.title}</h3>
          <img src={offerDetails.image} />
        </div>
        <div className={`bottom-section ${offerDetails.colour}`}>
          <div className="qr-code-download">
            <img src={qrCode} />
            <p>
              Scan to
              <br />
              download
              <br />
              Wisr App
            </p>
          </div>
          <Button button="primary" theme="navy" fullWidth>
            <a
              href={
                isBrowserMobile()
                  ? "https://wisr-app.onelink.me/zTOl/ox5owry4"
                  : `${BRAND_WEBSITE}/wisr-app`
              }
              rel="noreferrer"
              target="_blank"
            >
              {isBrowserMobile() ? "Download Wisr App" : "Learn more"}
            </a>
          </Button>
        </div>
      </div>
    </WidgetComponent>
  );
};
