import React from "react";
import { FetchStatus } from "../../../../shared/redux.consts";
import { Button, StatusCallout } from "@wisr/react-ui";
import { navigate } from "gatsby";

export const getWidgetCopy = (hasActiveBroker: boolean) => {
  if (hasActiveBroker) {
    return "Finding out is easy and it won’t affect your credit scores.";
  }

  return "Quickly see your personalised rate estimate, it won’t affect your credit scores.";
};

export const getBrokerContactSection = (
  brokerContacted: string,
  clickedContactBroker: () => void
) => {
  switch (brokerContacted) {
    case FetchStatus.IDLE:
      return (
        <>
          <p>
            We can let them know that you&rsquo;re interested in seeing your
            personalised rate with Wisr.
          </p>
          <Button theme="teal">
            <button type="button" onClick={() => clickedContactBroker()}>
              Yes contact my broker
            </button>
          </Button>
          <p>
            Alternatively you can{" "}
            <a onClick={() => navigate("/quote")}>
              check your rate estimate directly with Wisr.
            </a>
          </p>
        </>
      );
    case FetchStatus.SUCCESS:
      return (
        <StatusCallout
          type="success"
          theme="outline"
          heading="We’ve contacted your broker"
          message="You should hear from them soon. In the meantime, we’ve sent you a confirmation email for your request."
        />
      );
    case FetchStatus.FAILED:
      return (
        <StatusCallout
          type="error"
          theme="outline"
          heading="Something went wrong"
          message={
            <span>
              We weren&rsquo;t able to send your request.{" "}
              <a onClick={() => clickedContactBroker()}>Try again</a>
            </span>
          }
        />
      );
  }
};
