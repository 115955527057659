export const getRateOfferCopy = (purpose: string) => {
  switch (purpose.toLowerCase()) {
    case "car":
      return "We've got three great car loan options tailor-made just for you.";
    case "consolidation":
      return "We've got three great loan options waiting for you.";
    case "home-improvements":
      return "We've got three great renovation loan options waiting for you.";
    default:
      return "We've put together three great options for your personal loan, c'mon let's go!";
  }
};

export const getRateOfferPurposeText = (purpose: string) => {
  if (purpose === "home-improvements") {
    return "Home renovation";
  } else if (purpose === "consolidation") {
    return "Debt consolidation";
  } else {
    const purposeWithoutDash = purpose.replace(/-/g, " ");
    return (
      purposeWithoutDash.charAt(0).toUpperCase() + purposeWithoutDash.slice(1)
    );
  }
};
