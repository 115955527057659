import { Button } from "@wisr/react-ui";
import React from "react";
import image from "../../../../assets/images/binoculars.svg";
import { analyticsCustomEvent } from "../../../../shared/analytics/analytics.utils";
import { ImageWidget } from "../../../shared/image/image-widget.component";
import style from "./split-rate-estimate-widget.scss";
import { RATE_ESTIMATE_BID } from "../../../../shared/bid.consts";
import { QUOTE_FORM } from "../../../../shared/url.consts";
import { useGetUser } from "../../../../user/user.hooks";

export const SplitRateEstimateWidget = () => {
  const user = useGetUser();

  React.useEffect(() => {
    analyticsCustomEvent(
      "presentedDashboardOffer",
      {
        "Dashboard offer": "Rate estimate",
        Location: "Dashboard home",
      },
      {
        "RE Split Tests": "Dash_RE_Experiment_v2",
      }
    );
    analyticsCustomEvent("presentedDashboardOfferWithEmail", {
      "Dashboard offer": "Rate estimate",
      Location: "Dashboard home",
      email: user.profile.email,
    });
  }, [user.profile.email]);

  return (
    <ImageWidget
      background="info"
      image={image}
      headline="Looking for a smarter way to borrow?"
    >
      <style jsx>{style}</style>
      <div className="title">
        <p>{title}</p>
      </div>
      <div className="content">
        <ul>
          {data.map((item) => {
            return <li key={item}>{item}</li>;
          })}
        </ul>
      </div>
      <div className="cta">
        <Button theme="teal">
          <a
            href={`${QUOTE_FORM}?wcToken=${user.access_token}&Product=Dashboard&bid=${RATE_ESTIMATE_BID}`}
            target="_blank"
            rel="noopener nofollow"
            onClick={() => {
              analyticsCustomEvent("clickedWidgetOffer", {
                "Dashboard offer": "Rate estimate",
                Location: "Dashboard home",
              });
            }}
          >
            <div className="button-text">Get my rate</div>
          </a>
        </Button>
      </div>
    </ImageWidget>
  );
};

const title =
  "Bathroom renos, car upgrades, well-overdue holidays – some stuff just can’t wait.";

const data = [
  "Score a low-rate personal loan with no early repayment fees.",
  "Get an estimate in less than 2 minutes, without impacting your credit scores.",
];
