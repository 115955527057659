import breachAlertImage from "../../../assets/images/app-product/app-breach-alert.svg";
import creditScoresImage from "../../../assets/images/app-product/app-credit-score.svg";
import loanImage from "../../../assets/images/app-product/app-loan.svg";
import homePageImage from "../../../assets/images/app-product/app-home-page.svg";
import { HomeProductsResponse } from "../../../types/home-products";
import { isNotEmpty } from "@wisr/common";

export const getAppOfferDetails = (
  homeProducts?: HomeProductsResponse
): AppOfferDetails => {
  if (!homeProducts) {
    return defaultOfferDetails;
  }

  const hasScores = isNotEmpty(homeProducts.creditScore);
  const hasLoan = isNotEmpty(homeProducts.loans);
  const hasRoundUps = isNotEmpty(homeProducts.roundups);

  switch (true) {
    case hasScores && hasLoan && hasRoundUps:
      return {
        title: "Match your repayment frequency to payday",
        image: homePageImage,
        colour: "teal",
        products: "all products",
      };
    case hasScores && hasLoan && !hasRoundUps:
      return {
        title: "Pay your loan down faster with Round Up",
        image: homePageImage,
        colour: "green",
        products: "credit scores and loan",
      };
    case hasScores && !hasLoan && hasRoundUps:
      return {
        title: "Protect your data with BreachAlert",
        image: breachAlertImage,
        colour: "orange",
        products: "credit scores and round ups",
      };
    case !hasScores && hasLoan && hasRoundUps:
      return {
        title: "Make extra loan repayments anytime",
        image: homePageImage,
        colour: "teal",
        products: "loan and round ups",
      };
    case hasScores && !hasLoan && !hasRoundUps:
      return {
        title: "Get notified when your scores change",
        image: creditScoresImage,
        colour: "orange",
        products: "credit scores",
      };
    case !hasScores && hasLoan && !hasRoundUps:
      return {
        title: "Make extra loan repayments anytime",
        image: loanImage,
        colour: "teal-2",
        products: "loan",
      };
    default:
      return defaultOfferDetails;
  }
};

const defaultOfferDetails: AppOfferDetails = {
  title: "Make a smart money decision today",
  image: homePageImage,
  colour: "green",
  products: "no products",
};

interface AppOfferDetails {
  title: string;
  image: string;
  colour: string;
  products: string;
}
