import { isBrowser } from "@wisr/common";
import dayjs from "dayjs";
import { WisrProfile } from "../../../redux/wisr-profile/wisr-profile";
import { AttemptItem } from "../../../types/mobile-verification";

export const incrementAttempt = () => {
  if (!isBrowser()) return true;

  const attemptItem = window.sessionStorage.getItem("sendAttempts");
  if (!attemptItem) {
    window.sessionStorage.setItem(
      "sendAttempts",
      JSON.stringify(defaultAttemptState)
    );
    return true;
  }

  const attempt: AttemptItem = JSON.parse(attemptItem);
  if (attempt.count >= 10) {
    const attemptWithTimer: AttemptItem = {
      count: 0,
      timerStart: dayjs().add(10, "minute"),
    };
    window.sessionStorage.setItem(
      "sendAttempts",
      JSON.stringify(attemptWithTimer)
    );
    return false;
  } else if (attempt.count === 0 && attempt.timerStart) {
    if (dayjs().isAfter(dayjs(attempt.timerStart.toString()))) {
      window.sessionStorage.setItem(
        "sendAttempts",
        JSON.stringify(defaultAttemptState)
      );
      return true;
    } else {
      return false;
    }
  }

  const newAttempt: AttemptItem = {
    count: attempt.count + 1,
    timerStart: null,
  };
  window.sessionStorage.setItem("sendAttempts", JSON.stringify(newAttempt));
  return true;
};

export const defaultAttemptState: AttemptItem = { count: 1, timerStart: null };

export const getVerifiedMobileNumbers = (
  wisrProfile: WisrProfile | undefined
) => {
  if (!wisrProfile) return [];

  const verifiedMobiles = wisrProfile.mobiles.filter((mobile) => {
    return mobile.isVerified;
  });
  return verifiedMobiles.map((mobile) => {
    return mobile.normalizedMobile;
  });
};
